import React from "react"
import { navigate } from "gatsby"
import { Pagination } from "@material-ui/lab"

const Pagenation = ({ pageContext }) => {
  const { numberOfPages, humanPageNumber } = pageContext

  const handleChange = (_event, value) => {
    value === 1 ? navigate(`/`) : navigate(`/page/${value}`)
  }
  return (
    <div className="page-numbers">
      <Pagination
        variant="outlined"
        defaultPage={humanPageNumber}
        count={numberOfPages}
        onChange={handleChange}
      />
    </div>
  )
}

export default Pagenation
